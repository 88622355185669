/* WEB BUTTON */
.web-button {
}

.maintenance button,
.web-button button,
.button-container button {
  background-color: var(--mainTeal);
  min-width: 80px;
  padding: 0px 20px 0px 20px;
  width: fit-content;
  height: 35px;
  border-radius: 2px;
  text-align: center;
  color: white;
  cursor: pointer;
  margin-top: 15px;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  font-family: "raleway";
  /*box-shadow: var(--box-shadow);*/
}

.maintenance button:hover,
.web-button button:hover,
.button-container button:hover {
  background-color: var(--mainGray);
  background-color: var(--mainOrange);
  color: white;
  box-shadow: none;
}
