.hardware {
  /*border: 1px solid rgb(102, 255, 0);*/
  position: relative;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  background-color: var(--white);
  background: no-repeat url("../../assets/comp-inside.webp");
  background-size: cover;
  background-position: 20% 20%;
  -webkit-animation: fade-in-fwd 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  padding-bottom: 20px;
}

.hardware h1 {
  color: var(--mainOrange);
  padding: 15px 5px 0px 15px;
  font-size: 20px;
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.hardware h3,
.hardware p {
  white-space: pre-line;
  letter-spacing: 0.2px;
  padding: 15px 5px 5px 15px;
  font-weight: 400;
  color: var(--thirdGray);
  -webkit-animation: text-focus-in 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* Color Cube *
.service-cube-packs:after,
.service-cube-offers:after {
  font-family: "Material Icons";
  content: "\e5df";
  position: absolute;
  /*bottom: 0;*
  top: 90px;
  left: 20px;
  height: 1px;
  width: 1px;
  font-size: 100px;
  transform: rotate(225deg);
}

.service-cube-offers:after {
  color: var(--mainOrange);
}

.service-cube-packs:after {
  color: var(--mainBlue);
}
/* END Color Cube */

.hardware-bottom-container,
.hardware-top-container {
  display: flex;
  position: relative;
  width: 90%;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 0;
  text-align: left;
  border-radius: 2px;
}

.hardware-top-container {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background: none;
  flex-flow: row wrap;
  justify-content: space-between;
  height: fit-content;
  padding: 15px;
}

.hardware-top-container .elements {
  display: flex;
  position: relative;
  background: rgba(30, 30, 30, 0.9);
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  width: 100%;
  padding: 30px 30px 20px 30px;
  flex-flow: column;
  border-radius: 2px;
  box-shadow: var(--box-shadow);
}

.hardware-bottom-container {
  margin-top: 20px;
  background: none;
  flex-flow: row wrap;
  justify-content: space-between;
  height: fit-content;
  padding: 15px;
  padding-bottom: 40px;
}

.hardware-bottom-container .elements {
  display: flex;
  position: relative;
  background: rgba(30, 30, 30, 0.9);
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  width: 400px;
  height: 350px;
  padding: 30px 30px 20px 30px;
  margin-bottom: 20px;
  flex-flow: column;
  border-radius: 2px;
  box-shadow: var(--box-shadow);
}

.hardware-bottom-container .elements h1,
.hardware-bottom-container .elements h3 {
  width: 100%;
  height: fit-content;
  padding: 15px 5px 5px 15px;
  font-size: 18px;
}

.hardware-bottom-container .elements h1.title {
  color: var(--blue);
  font-size: 18px;
}

.hardware-bottom-container .elements h3 {
  padding-top: 0;
  padding-bottom: 10px;
  color: var(--blue);
}

.hardware-bottom-container .elements p {
  display: flex;
  height: fit-content;
  font-size: 14px;
  letter-spacing: 1px;
  padding-left: 10px;
}

.hardware-bottom-container p:before {
  font-family: "Material Icons";
  content: "\e5cc";
  align-items: center;
  position: relative;
  top: 0px;
  color: var(--mainTeal);
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* ARROW BACK */
.hardware .back-arrow {
  display: flex;
  position: absolute;
  color: white;
  left: -100px;
  top: 35px;
  cursor: pointer;
  height: fit-content;
  justify-content: center;
  width: 50px;
  height: calc(100% - 35px);
  background: rgba(30, 30, 30, 0.2);
  transition: all 1s ease-in-out;
}

.hardware .back-arrow.on {
  left: 0px;
}

.hardware .back-arrow .material-icons {
  font-size: 40px;
  color: white;
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  margin: auto;
}

.hardware .back-arrow .material-icons:hover {
  color: var(--mainOrange);
}

.hardware-bottom-container p {
  padding-bottom: 0;
  padding-top: 10px;
  line-height: 20px;
  height: 100%;
}

.hardware p.set {
  font-weight: 600;
  color: var(--blue);
}

.hardware span {
  color: var(--mainOrange);
  padding-left: 5px;
}

/* BUTTON */
.hardware-button {
  width: 100%;
  position: absolute;
  padding-bottom: 20px;
  bottom: 0;
  left: 35%;
  right: 35%;
  justify-content: center;
}

.hardware-button button {
  background-color: var(--mainTeal);
  min-width: 80px;
  padding: 0px 20px 0px 20px;
  width: fit-content;
  height: 35px;
  border-radius: 1px;
  text-align: center;
  color: white;
  cursor: pointer;
  margin-top: 15px;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
}

.hardware-button button:hover {
  background-color: var(--mainGray);
  background-color: var(--mainOrange);
  color: white;
}
/* END Button */
