.services {
  /*border: 1px solid rgb(102, 255, 0);*/
  position: relative;
  display: flex;
  flex-grow: 1;
  background-color: var(--white);
  background: url("/src/assets/comp-inside.webp");
  background-size: cover;
  background-position: 100% 100%;
  -webkit-animation: fade-in-fwd 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.services-box-container {
  background: rgba(30, 30, 30, 0.9);
  width: 500px;
  height: fit-content;
  margin: 70px;
  align-items: center;
  justify-content: left;
  padding: 40px;
  box-shadow: var(--box-shadow);
  border-radius: 2px;
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  /*-webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;*/
}

@media screen and (max-width: 600px) {
  .services-box-container {
    padding: 40px;
    width: 90%;
    margin: 40px auto 0 auto;
    justify-content: center;
  }
}

.services-box-container h3 {
  position: relative;
  z-index: 11;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0.5px;
  -webkit-animation: fade-in 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/*
.services-cube:after {
  font-family: "Material Icons";
  content: "\e5df";
  position: absolute;
  /*bottom: 0;*
  top: 90px;
  left: 20px;
  height: 1px;
  width: 1px;
  color: var(--mainBlue);
  font-size: 100px;
  transform: rotate(225deg);
  box-shadow: var(--box-shadow);
}

.services-cube-bottom:after {
  font-family: "Material Icons";
  font-size: 100px;
  content: "\e5df";
  position: absolute;
  /*bottom: 0;*
  bottom: 90px;
  right: 20px;
  height: 1px;
  width: 1px;
  color: var(--mainOrange);
  transform: rotate(45deg);
  box-shadow: var(--box-shadow);
}
*/

.services-title-top,
.services-title {
  display: flex;
  align-items: center;
  color: var(--mainOrange);
}
.services-title-top,
.services-title h1 {
  font-size: 16px;
  letter-spacing: 0.7px;
  padding-bottom: 20px;
}

.services-title-top {
  font-size: 20px;
  padding-bottom: 10px;
}

/* WEB BUTTON */
.services-button button {
  background-color: var(--mainTeal);
  min-width: 80px;
  padding: 0px 20px 0px 20px;
  width: fit-content;
  height: 35px;
  border-radius: 1px;
  text-align: center;
  color: white;
  cursor: pointer;
  margin-top: 15px;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
}

.services-button button:hover {
  background-color: var(--mainGray);
  background-color: var(--mainOrange);
  color: white;
}

.services .new-line {
  white-space: pre-line;
}

/* Details */
.service-box-offers-container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 80%;
  min-width: 375px;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0px 0 0px 0;
  margin-top: 70px;
}

.service-intro-container {
  position: relative;
  display: flex;
  width: fit-content;
  padding: 10px;
}

.service-offers-intro {
  position: relative;
  display: flex;
  flex-flow: column;
  width: fit-content;
  padding: 40px 40px 20px 40px;
  background: rgba(30, 30, 30, 0.9);
  -webkit-animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  box-shadow: var(--box-shadow);
}

@media screen and (max-width: 600px) {
  .service-box-offers-container {
    padding: 20px;
    margin: 20px auto 0 auto;
    justify-content: center;
  }

  .service-intro-container {
  }

  .service-offers-intro {
    padding: 40px;
    margin: 40px auto 0 auto;
    justify-content: center;
  }
}

.service-offers-bottom h1,
.service-offers-intro h1 {
  font-size: 18px;
  padding-bottom: 10px;
  color: var(--mainOrange);
}

.service-offers-bottom h3,
.service-offers-intro h3 {
  color: var(--thirdGray);
  line-height: 25px;
  letter-spacing: 0.7px;
  font-weight: 200;
}

/*
.service-cube-offers-intro:after {
  font-family: "Material Icons";
  content: "\e5df";
  position: absolute;
  /*bottom: 0;*
  top: 90px;
  left: 20px;
  height: 1px;
  width: 1px;
  color: var(--mainOrange);
  font-size: 100px;
  transform: rotate(225deg);
  box-shadow: var(--box-shadow);
}
*/

/* Service Bottom */
.service-offers-bottom {
  display: flex;
  position: relative;
  flex-flow: column;
  width: fit-content;
  padding: 10px;
  margin-bottom: 70px;
}

/*
.service-offers-bottom .elements .web-cube-offers:after,
.service-offers-bottom .elements .web-cube-offers:after {
  font-family: "Material Icons";
  content: "\e5df";
  position: absolute;
  /*bottom: 0;*
  top: 90px;
  left: 20px;
  height: 1px;
  width: 1px;
  color: var(--mainBlue);
  font-size: 100px;
  transform: rotate(225deg);
  box-shadow: var(--box-shadow);
}
*/

.service-offers-bottom .elements {
  display: flex;
  position: relative;
  flex-flow: column;
  width: 100%;
  background: rgba(30, 30, 30, 0.9);
  box-shadow: var(--box-shadow);
  padding: 40px;
  margin: 20px 0 20px 0;
}

.service-offers-bottom .elements h1.one {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.service-offers-bottom .elements h1.two {
  -webkit-animation: slide-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.service-offers-bottom .elements h1.three {
  -webkit-animation: slide-in-right 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.elements .details {
  display: flex;
  flex-flow: column;
  position: relative;
}

.service-offers-bottom .elements h1 {
  padding-bottom: 20px;
}

.service-offers-bottom .elements h1,
.service-offers-bottom .elements h3 {
  white-space: pre-line;
}

.service-offers-bottom .elements h3.title,
.service-offers-bottom .elements h3 {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.service-offers-bottom .elements h3.title {
  font-weight: 600;
  color: var(--blue);
}
