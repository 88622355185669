footer {
  /* border: 1px solid red;*/
  position: relative;
  display: flex;
  bottom: 0;
  height: 50px;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 500;
  color: var(--secondGray);
}
