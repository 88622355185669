main {
  /*border: 1px solid rgb(102, 255, 0);*/
  position: relative;
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  background-color: var(--white);
  background: url("/src/assets/home-page3.webp");
  background-size: cover;
  background-position: 20% 20%;
  -webkit-animation: fade-in-fwd 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
