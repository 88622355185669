.swiper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  margin: auto;
  z-index: 1;
}

/*
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically *
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
*/

.autoplay-progress {
  border-radius: 30px;
  font-family: "Inter";
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--swiper-theme-color);
  color: white;
  font-weight: 300 !important;
  font-size: 12px !important;
}

@media screen and (max-width: 600px) {
  .autoplay-progress {
    right: 16px;
    bottom: 16px;
  }
  .autoplay-progress span {
    position: relative;
    left: -15px;
  }

  .autoplay-progress svg {
    left: -148px;
    top: -24px;
  }
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: -22px;
  top: -24px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 5px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
  align-items: center;
  justify-content: center;
  text-align: center;
}

.swiper-pagination-bullet {
  background-color: var(--mainTeal);
  height: 15px;
  width: 15px;
  opacity: 0.5;
}
.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-button-prev,
.swiper-button-next {
  opacity: 0.1;
  transition: all 0.2s ease-in-out;
  color: white;
  font-weight: 600;
  /* background-color: rgba(162, 162, 162, 0.7);*/
  width: 50px;
  height: 70px;
  border-radius: 3px;
  z-index: 10 !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
  z-index: 10 !important;
}

@media screen and (max-width: 600px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    visibility: hidden;
  }
}

.swiper-button-prev {
  padding: 20px;
  left: -1px;
  z-index: 10 !important;
}

.swiper-button-next {
  margin-right: 5px;
  right: -5px;
  z-index: 10 !important;
}
/*
.swiper-button-next {
  color: #000;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
}
*/

.slider {
  display: flex;
  height: 100%;
  width: 100%;
  overflow-x: initial !important;
}

/* Slide Page */
.slider-container {
  display: flex;
  height: fit-content;
  margin: auto;
  max-width: 100%;
  width: 650px;
  min-width: 375px;
  padding: 50px;
  margin-top: 20px;
  margin-left: 50px;
  z-index: 1 !important;
}

@media screen and (max-width: 600px) {
  .slider-container {
    padding: 20px;
    width: 100%;
    margin: 20px 0 0 0;
  }
}

.slider-background {
  position: relative;
  background: rgba(30, 30, 30, 0.9);
  padding: 40px;
  border-radius: 3px;
  /*  border-left: 5px solid rgba(255, 132, 0, 1);
border-right: 3px solid rgba(255, 132, 0, 1);*/
  z-index: 1 !important;
  box-shadow: var(--box-shadow);
}

.slider-title {
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-align: left;
  color: var(--blue);
  color: var(--lightGray);
  padding: 0px;
  padding-bottom: 10px;
  padding-top: 20px;
}

.slider-subtitle {
  font-family: "Raleway";
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.7px;
  text-align: left;
  color: var(--lightGray);
  color: var(--mainOrange);
  color: var(--blue);
  padding: 5px 0px 15px 0px;
  width: 450px;
}

.slider-text {
  font-family: "Raleway";
  font-size: 16px;
  letter-spacing: 0.3px;
  text-align: left;
  padding: 0px 0px 0px 0px;
  line-height: 25px;
  font-weight: 500;
  color: var(--lightGray);
}

@media screen and (max-width: 768px) {
  .swiper {
    position: relative;
    top: 0;
    width: 100%;
    height: 120vh;
    align-items: center;
    margin: auto;
    z-index: 1;
  }

  .slider-title {
    width: fit-content;
  }

  .slider-subtitle {
    width: fit-content;
  }

  .slider-text {
    width: fit-content;
  }

  .slider-background {
    padding: 25px;
    height: fit-content;
  }

  .slider-container {
    max-width: initial;
    min-width: initial;
    width: 100%;
    height: 100%;
    padding: 40px 20px 10px 20px;
    margin: 0px;
    z-index: 1 !important;
    margin-bottom: 20px;
  }
}

.slider-button {
  position: relative;
  width: 100%;
  display: flex;
  opacity: 0;
  right: 0;
  transition: all 0.3 ease-in-out;
  justify-content: left;
  text-align: left;
  padding-top: 20px;
}

.slider-button button {
  outline: none;
  color: white;
  display: flex;
  width: 100px;
  text-align: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 13px;
  padding: 10px;
  font-weight: 500;
  letter-spacing: 0.7px;
  cursor: pointer;
  border: 1px solid var(--mainTeal);
  background-color: var(--mainTeal);
  box-shadow: var(--box-shadow);
}

.slider-button button:hover {
  color: white;
  background-color: var(--mainOrange);
  border: 1px solid var(--mainOrange);
  box-shadow: none;
}

.img1 {
  background: no-repeat url("../../assets/web-design1.webp");
  background-size: cover;
  height: 100%;
  background-position: 60% 60%;
}

.img2 {
  background: no-repeat url("../../assets/web-design4.webp");
  background-size: cover;
  height: 100%;
  background-position: 40% 40%;
}

.img3 {
  background: no-repeat url("../../assets/maintenance.webp");
  background-size: cover;
  height: 100%;
  background-position: 30% 30%;
}

.img4 {
  background: no-repeat url("../../assets/software.webp");
  background-size: cover;
  height: 100%;
  background-position: 50% 50%;
}

.img5 {
  background: no-repeat url("../../assets/comp-inside.webp");
  background-size: cover;
  height: 100%;
  background-position: 0% 0%;
}

.img6 {
  /* background: no-repeat url("../../assets/t01-g.webp");*/
  background-size: cover;
  height: 100%;
  background-position: 50% 50%;
}
