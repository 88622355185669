.contact {
  /*border: 1px solid rgb(102, 255, 0);*/
  position: relative;
  display: flex;
  flex-grow: 1;
  background-color: var(--white);
  background: url("/src/assets/contact-form.webp");
  background-size: cover;
  background-position: 50% 50%;
  -webkit-animation: fade-in-fwd 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  justify-content: right;
}

.contact h1 {
  color: var(--mainOrange);
  padding: 15px 5px 0px 20px;
  font-size: 20px;
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.contact h3,
.contact p {
  white-space: pre-line;
  letter-spacing: 0.2px;
  padding: 10px 5px 0px 20px;
  font-weight: 400;
  line-height: 25px;
  color: var(--thirdGray);
  -webkit-animation: text-focus-in 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.contact p {
  padding: 0px 5px 2px 20px;
}

.contact .contact-title {
  color: var(--blue);
  font-size: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

/* MIDDLE CONTAINER */
.contact-container {
  position: relative;
  display: flex;
  background: rgba(30, 30, 30, 0.9);
  box-shadow: var(--box-shadow);
  margin: auto;
  margin: 70px;
  max-width: 1000px;
  padding: 20px 20px 40px 20px;
}

.contact-bottom {
  display: flex;
  flex-flow: row wrap;
}

.contact-bottom-left .phone-icon {
  font-family: "Poppins";
  letter-spacing: 1px;
  color: var(--blue);
  font-weight: 500;
  font-size: 22px;
  padding-top: 25px;
}

.phone-icon::before {
  font-family: "Material Icons";
  position: relative;
  content: "\e0b0";
  font-weight: 200;
  line-height: 0px;
  font-size: 25px;
  padding: 0 5px 0 0;
  top: 4px;
}

/* Top Container */
.contact-form {
  display: flex;
  flex-flow: column;
  max-width: 100%;
  flex-grow: 1;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-top: 0;
  -webkit-animation: scale-in-ver-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    0.5s both;
  animation: scale-in-ver-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s
    both;
}

form {
  width: 80%;
  margin-top: 10px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  outline: none;
  color: black;
  background-color: white;
  display: flex;
  padding: 7px;
  border-radius: 2px;
  font-size: 14px;
  margin: 10px 0 10px 0;
  box-shadow: var(--box-shadow);
}

.contact-form textarea {
  height: 125px;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: black;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

textarea:valid,
input:valid {
  background-color: white;
}

textarea:invalid,
input:invalid {
  background-color: var(--lightRed);
  border-color: var(--lightRed);
}

.none {
  padding: 0 !important;
  margin: 0 !important;
}

.error-message {
  color: var(--lightRed);
  padding: 0px 0 0 0;
  letter-spacing: 0.7px;
  font-size: 14px;
}

.text .error-message,
.message-sent {
  padding: 0px 0 5px 0;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 500;
  color: var(--lightRed);
}

.message-sent {
  color: var(--mainOrange);
}

.error-messages.off {
  display: none;
  visibility: hidden;
}

.error-messages .success {
  color: white;
  display: none;
  visibility: hidden;
}

.error-messages .error {
  display: flex;
  visibility: visible;
  color: var(--lightRed);
  background-color: rgb(0, 0, 0);
  font-weight: 300;
  letter-spacing: 1.2px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 13px;
}

/* SOCIAL MEDIA NETWORKS*/
.contact-media {
  display: flex;
  flex-flow: row wrap;
  color: white;
  max-width: 100%;
  justify-content: left;
}

.contact-socials {
  display: flex;
  flex-flow: row wrap;
  padding-left: 10px;
}
.between {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
}

svg,
.media-fb,
.media-in,
.media-ig,
.media-tw,
.media-wa,
.media-gt,
.media-yt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  /*filter: invert();*/
  margin: 1vw;
  margin-top: 25px;
  margin-bottom: 0px;
  filter: invert(90%) saturate(2878%) hue-rotate(190deg) brightness(104%)
    contrast(97%);
  fill: rgb(255, 255, 255);
}

svg:hover,
.media-fb:hover,
.media-in:hover,
.media-ig:hover,
.media-tw:hover,
.media-wa:hover,
.media-gt:hover,
.media-yt:hover {
  cursor: pointer;
  filter: invert(47%) sepia(51%) saturate(1878%) hue-rotate(10deg)
    brightness(104%) contrast(100%);
  fill: rgb(255, 255, 255);
}

.media-fb {
  background-image: url("../../assets/logo-facebook.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.media-wa {
  background-image: url("../../assets/logo-whatsapp.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.media-tw {
  background-image: url("../../assets/logo-twitter.svg");
  background-repeat: no-repeat;
  background-size: 50% cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.media-ig {
  background-image: url("../../assets/logo-instagram.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.media-in {
  background-image: url("../../assets/logo-linkedin.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.media-yt {
  background-image: url("../../assets/logo-youtube.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.media-gt {
  background-image: url("../../assets/logo-github.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.qr-code {
  display: flex;
  position: relative;
  background-image: url("../../assets/qr-code.png");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  width: 100px;
  height: 100px;
  border: 3px solid white;
  right: 0;
  bottom: 30px;
  border-radius: 2px;
}

.contact .button-container {
  justify-content: right;
  text-align: right;
}

@media screen and (max-width: 600px) {
  .contact {
    padding: 40px;
    justify-content: center;
  }

  .contact-container {
    margin: auto;
  }
  .contact-form {
    width: 100%;
    padding: 0;
  }
  .contact-media .qr-code {
    top: 5px;
  }

  iframe {
    position: absolute;
    width: 310px !important;
    left: 0;
  }
}
