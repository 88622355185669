.web {
  position: relative;
  display: flex;
  flex-grow: 1;
  background-color: var(--white);
  background: url("/src/assets/web-design1.webp");
  background-size: cover;
  background-position: 100% 100%;
  -webkit-animation: fade-in-fwd 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.web-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-flow: column;
}

.web-box-container {
  background: rgba(30, 30, 30, 0.9);
  width: 500px;
  height: fit-content;
  margin: 70px;
  align-items: center;
  padding: 40px;
  box-shadow: var(--box-shadow);
  border-radius: 2px;
  -webkit-animation: fade-in 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@media screen and (max-width: 600px) {
  .web-box-container {
    padding: 40px;
    width: 90%;
    margin: 40px auto 0 auto;
    justify-content: center;
  }
}

.web-box-container h3 {
  position: relative;
  z-index: 11;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0.5px;
  -webkit-animation: fade-in 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* CARET BOX *
.web-cube:after {
  font-family: "Material Icons";
  content: "\e5df";
  position: absolute;
  /*bottom: 0;*
  top: 90px;
  left: 20px;
  height: 1px;
  width: 1px;
  color: var(--mainBlue);
  font-size: 100px;
  transform: rotate(225deg);
  box-shadow: var(--box-shadow);
}

.web-cube-bottom:after {
  font-family: "Material Icons";
  font-size: 100px;
  content: "\e5df";
  position: absolute;
  /*bottom: 0;*
  bottom: 90px;
  right: 20px;
  height: 1px;
  width: 1px;
  color: var(--mainOrange);
  transform: rotate(45deg);
  box-shadow: var(--box-shadow);
}

*/

/* RIGHT CONTENT */
.web-box-offers-container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 80%;
  min-width: 375px;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0px 0 0px 0;
  margin-top: 70px;
  height: fit-content;
}

.web-offers-intro-container {
  position: relative;
  display: flex;
  width: fit-content;
  margin-bottom: 10px;
  justify-content: center;
  margin: auto;
  padding: 10px;
}

/* TOP CONTENT - INTRO */
.web-offers-intro {
  position: relative;
  display: flex;
  flex-flow: column;
  min-width: 320px;
  padding: 40px 40px 20px 40px;
  background: rgba(30, 30, 30, 0.9);
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  box-shadow: var(--box-shadow);
}

.web-offers-intro h1 {
  font-size: 18px;
  padding-bottom: 10px;
  color: var(--mainOrange);
}

.web-offers-intro h3 {
  color: var(--thirdGray);
  line-height: 25px;
  letter-spacing: 0.7px;
  font-weight: 200;
}

/*
.web-cube-offers-intro:after {
  font-family: "Material Icons";
  content: "\e5df";
  position: absolute;
  /*bottom: 0;*
  top: 90px;
  left: 20px;
  height: 1px;
  width: 1px;
  color: var(--mainOrange);
  font-size: 100px;
  transform: rotate(225deg);
  box-shadow: var(--box-shadow);
}
*/

@media screen and (max-width: 600px) {
  .web-offers-intro {
    width: 100%;
    margin: 10px 100px 10px 100px;
  }
}

/* BOTTOM CONTENT */
.web-box-container-right {
  display: flex;
  flex-flow: column;
  background: rgba(30, 30, 30, 0.9);
  width: 250px;
  height: fit-content;
  height: 350px;
  margin: 0px 0px 0px 0px;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0px 10px 0px;
  box-shadow: var(--box-shadow);
}

.web-box-container-right.one {
  -webkit-animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.web-box-container-right.two {
  -webkit-animation: text-focus-in 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.web-box-container-right.three {
  -webkit-animation: text-focus-in 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.web-box-container-right.four {
  -webkit-animation: text-focus-in 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.web-box-container-right.five {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/*
.web-cube-offers-right:after {
  font-family: "Material Icons";
  content: "\e5df";
  position: absolute;
  /*bottom: 0;*
  top: 90px;
  left: 20px;
  height: 1px;
  width: 1px;
  color: var(--mainBlue);
  font-size: 100px;
  transform: rotate(225deg);
  box-shadow: var(--box-shadow);
}
*/

.web-offers-details {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.web-box-offers {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 10px;
  height: fit-content;
  margin-top: 30px;
}

.web-title-top,
.web-title {
  display: flex;
  align-items: center;
  color: var(--mainOrange);
}

.web-title-top,
.web-title h1 {
  font-size: 16px;
  letter-spacing: 0.7px;
  padding-bottom: 20px;
}

.web-title-top {
  font-size: 20px;
  padding-bottom: 10px;
}

.web-content-details {
  flex-grow: 1;
  padding-top: 0;
}

.web-content-details h3 {
  padding-bottom: 10px;
  font-weight: 300;
  text-align: center;
}

.web-price {
  display: flex;
}

.web-box-details {
  display: flex;
  flex-flow: column;
  justify-content: left;
  text-align: left;
}
.web-content-details span,
.web-content-details p {
  font-weight: 200;
  line-height: 25px;
  color: var(--thirdGray);
  font-size: 14px;
  letter-spacing: 0.7px;
}

.web-content-details p.title {
}

.web-content-details p.title::before {
}

.web-content-details p:before {
  font-family: "Material Icons";
  content: "\e5cc";
  align-items: center;
  margin: auto;
  position: relative;
  top: 2px;
  color: var(--mainTeal);
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.web-price {
  font-weight: 500;
  line-height: 25px;
  color: var(--mainOrange);
  font-size: 16px;
  padding: 10px;
  padding-bottom: 20px;
  font-family: "Poppins" !important;
}

.web-box-details p.web-line {
  padding: 0 30px 0 30px;
}

.web-price span {
  color: var(--thirdGray);
  font-weight: 300;
  font-size: 12px;
  padding-right: 5px;
}

.web-price p {
  font-family: "Inter";
}

@media screen and (max-width: 600px) {
  .web-box-container-right {
    width: 100%;
    padding: 20px 40px 0 40px;
    margin: 20px auto 20px auto;
    height: fit-content;
  }

  .web-box-offers {
    width: 90%;
    margin: 20px auto 20px auto;
    text-align: left;
    justify-content: left;
  }

  .web-title {
    width: 100%;
    padding: 20px 0 0 0;
  }
}
