@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,800;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300&display=swap");
@import url("./global.css");
@import url("./animation.css");
@import url("../components/styles/header.css");
@import url("../components/styles/logo.css");
@import url("../components/styles/navBar.css");
@import url("../components/styles/secondNavBar.css");
@import url("../components/styles/container.css");
@import url("../components/styles/buttons.css");
@import url("../components/styles/arrowBack.css");
@import url("../components/styles/responsiveNavbar.css");
@import url("../pages/styles/home.css");
@import url("../pages/styles/services.css");
@import url("../pages/styles/webServices.css");
@import url("../pages/styles/maintenance.css");
@import url("../pages/styles/hardware.css");
@import url("../pages/styles/software.css");
@import url("../pages/styles/packs.css");
@import url("../pages/styles/contact.css");
@import url("../components/styles/footer.css");

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Raleway";
  border: 0;
  user-select: none;
  -webkit-overflow-scrolling: touch;
  list-style: none;
  text-transform: initial;
}

a {
  cursor: pointer;
  color: var(--mainGray);
}

a:hover {
  color: var(--mainOrange);
}

a.active {
  color: var(--mainOrange);
}

h1,
h2,
h3,
h4,
p,
span {
  font-family: "Raleway";
  font-weight: 400 !important;
}

h1 {
  font-family: "Raleway";
  font-size: 18px;
  font-weight: 500 !important;
}

h3 {
  font-family: "Raleway";
  font-size: 16px;
  color: white;
}

html {
  /*  border: 1px solid red;*/
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  /*  border: 1px solid rgb(255, 183, 0);*/
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 375px;
}

.App {
  /*border: 2px solid rgb(81, 255, 0);*/
  display: flex;
  position: relative;
  min-height: 100vh;
  width: 100vw;
  flex-flow: column;
}

@media screen and (max-width: 600px) {
  html,
  body,
  .App {
    overflow-x: clip;
  }
  .software {
    overflow-x: hidden;
  }
}

.hide,
.hidden,
.off {
  display: none;
  visibility: hidden;
}

.show,
.on {
  display: contents;
  visibility: visible;
}

.jump-top {
  z-index: 100;
  position: fixed;
  height: fit-content;
  width: fit-content;
  font-size: 40px;
  padding: 10px;
  right: 10px;
  bottom: 20px;
  color: white;
  background: rgba(30, 30, 30, 0.5);
  transition: all 1s ease-in-out;
  cursor: pointer;
  -webkit-animation: slide-top 1s cubic-bezier(0.075, 0.82, 0.165, 1) both;
  animation: slide-top 1s cubic-bezier(0.075, 0.82, 0.165, 1) both;
}

.material-symbols-outlined.jump-top:hover {
  color: var(--mainOrange);
}

@media screen and (max-width: 600px) {
  .jump-top {
    color: rgb(0, 0, 0);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 3;
    box-shadow: var(--box-shadow);
    font-weight: 800;
  }
}

.out {
  transition: all 0.4s ease-in-out;
  visibility: hidden;
  overflow: hidden;
  height: 0;
  -webkit-animation: fade-out 0.1s ease-out both;
  animation: fade-out 0.1s ease-out both;
  -webkit-animation: slide-out-left 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-left 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.elements.one {
  -webkit-animation: slide-in-left 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.elements.two {
  -webkit-animation: slide-in-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.elements.three {
  -webkit-animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.elements.four {
  -webkit-animation: slide-in-left 1.1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 1.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* Color Cube *
.cube:after {
  font-family: "Material Icons";
  content: "\e5df";
  position: absolute;
  /*bottom: 0;*
  top: 90px;
  left: 20px;
  height: 1px;
  width: 1px;
  font-size: 100px;
  transform: rotate(225deg);
  color: var(--mainOrange);
}
*/

button {
  font-size: 13px !important;
  text-transform: capitalize;
  font-weight: 300 !important;
}
