.logo {
  position: relative;
  display: flex;
  height: fit-content;
  padding: 10px 0px 10px 20px;
  cursor: pointer;
  max-width: 250px;
  z-index: 10;
}

.image-container {
  min-width: 250px;
}

.image-container img {
  width: 145px;
  height: 65px;
}

.small-logo {
  display: none;
  visibility: hidden;
}

@media screen and (max-width: 1000px) {
  .image-container img {
    position: relative;
    width: 145px;
    height: 65px;
  }
  .small {
    display: none;
    visibility: hidden;
  }
}

@media screen and (max-width: 600px) {
  .logo {
    justify-content: left;
    margin: 0;
    padding: 0px 0px 0px 0px;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    display: none;
    visibility: hidden;
  }
  .small {
    display: none;
    visibility: hidden;
  }

  .small-logo {
    position: absolute;
    top: 14px;
    left: -8px;
    width: 40px;
    padding: 0;
    display: flex;
    visibility: visible;
  }
}

@media screen and (max-width: 350px) {
  .small-logo {
    position: absolute;
    top: 14px;
    left: -15px;
    width: 40px;
    padding: 0;
    display: flex;
    visibility: visible;
  }
}

/*
.image-logo {
  background: url("/src/assets/icoms_logo.svg");
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
}
*/
