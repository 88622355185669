.pack {
  /*border: 1px solid rgb(102, 255, 0);*/
  position: relative;
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  background-color: var(--white);
  background: no-repeat url("../../assets/web-design4.webp");
  background-size: cover;
  background-position: 20% 20%;
  -webkit-animation: fade-in-fwd 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.pack h1 {
  color: var(--mainOrange);
  padding-bottom: 20px;
  font-size: 20px;
  -webkit-animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.pack h3,
.pack p {
  letter-spacing: 0.2px;
  font-weight: 400;
  color: var(--thirdGray);
  -webkit-animation: text-focus-in 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.pack-navigation::after {
  display: flex;
  font-family: "Material Icons";
  content: "\e5c4";
  font-size: 40px;
  color: var(--mainTeal);
  position: relative;
  margin: 10px;
  padding: 10px;

  /* box-shadow: var(--box-shadow);*/
  cursor: pointer;
}

.pack-navigation:hover::after {
  background: rgba(30, 30, 30, 0.05);
  color: var(--mainOrange);
}

/* PACK MIDDLE CONTAINER */
.pack-container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 100px 100px 70px 100px;
  -webkit-animation: fade-in-fwd 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  margin: auto;
  justify-content: center;
}

.pack-middle-elements {
  background: rgba(30, 30, 30, 0.8);
  position: relative;
  display: flex;
  flex-flow: column;
  padding: 40px 40px 40px 40px;
  height: 500px;
  min-height: 480px;
  width: 350px;
  max-width: 375px;
  box-shadow: var(--box-shadow);
  margin: auto auto 40px auto;
}

@media screen and (max-width: 700px) {
  .pack-container {
    padding: 60px 15px 0 15px;
    margin: auto;
    justify-content: center;
  }
  .pack-middle-elements {
    margin: 10px 15px 10px 15px;
    max-width: 375px;
  }
}

.top-element {
  display: flex;
  flex-grow: 1;
  padding-bottom: 20px;
}

.top-element p.extra {
  font-size: 13px;
  font-weight: 200;
  align-items: bottom;
  bottom: 0;
}

.pack-middle-elements h3 {
  padding-bottom: 15px;
  font-size: 18px;
  color: var(--blue);
}

.pack-middle-elements p:before {
  font-family: "Material Icons";
  content: "\e5cc";
  align-items: center;
  margin: auto;
  position: relative;
  top: 2px;
  color: var(--mainTeal);
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
}

.pack-middle-elements p {
  /*padding: 3px 10px 3px 10px;*/
  line-height: 25px;
  font-size: 15px;
}

.pack-middle-elements .web-button {
  display: flex;
  text-align: center !important;
  position: relative;
  margin: auto;
}

.pack-middle-elements .web-button button {
  margin-top: 0;
}

/*
.packs-elements .web-cube-offers:after,
.pack-middle-elements .web-cube-offers:after {
  font-family: "Material Icons";
  content: "\e5df";
  position: absolute;
  /*bottom: 0;*
  top: 90px;
  left: 20px;
  height: 1px;
  width: 1px;
  color: var(--mainBlue);
  font-size: 100px;
  transform: rotate(225deg);
  box-shadow: var(--box-shadow);
}

.web-cube-offers.title:after {
  color: var(--mainOrange);
}
*/

.elements-left {
  -webkit-animation: text-focus-in 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
.elements-middle-left {
  -webkit-animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.elements-middle-right {
  -webkit-animation: text-focus-in 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.elements-right {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* ARROW BACK */
.back-arrow {
  display: flex;
  position: absolute;
  color: white;
  left: 0px;
  top: 35px;
  margin: auto;
  cursor: pointer;
  height: fit-content;
  justify-content: center;
  width: 100%;
  background: rgba(30, 30, 30, 0.5);
  transition: all 1s ease-in-out;
}

.back-arrow .material-icons {
  font-size: 40px;
  color: var(--mainOrange);
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.back-arrow .material-icons:hover {
  color: var(--mainTeal);
}

/* PACKS */
.packs-elements {
  box-shadow: var(--box-shadow);
  display: flex;
  flex-flow: column;
  position: relative;
  background: rgba(30, 30, 30, 0.9);
  width: 90%;
  padding: 40px;
  margin: auto;
  margin: 30px 0px 60px 0px;

  align-items: center;
}

@media screen and (max-width: 600px) {
  .pack-container.packs .back-arrow {
    left: 0px;
    top: 35px;
    bottom: initial;
    height: fit-content;
  }
}

.packs-title {
  display: flex;
  position: relative;
  flex-flow: column;
  text-align: left;
  justify-content: left;
  width: 100%;
}

.packs-details {
  display: flex;
  position: relative;
  flex-flow: column;
}

.details-container {
  display: flex;
  position: relative;
  flex-flow: column;
  text-align: left;
  /*border-bottom: 1px solid rgba(255, 255, 255, 0.05);*/
  padding: 20px;
}

/*
.details-container:hover {
  box-shadow: none;
  cursor: pointer;
  background: rgba(30, 30, 30, 0.8);
}
*/

.details-container h3 {
  color: var(--blue);
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
}

.details-container h3 .title {
  color: var(--thirdGray);
  padding: 10px;
}

.details-container h3 .price {
  font-family: "Inter";
  color: var(--mainOrange);
}

.details-container p {
  line-height: 25px;
  padding: 0 20px 0px 20px;

  font-size: 17px;
  font-weight: 400;
  white-space: pre-line;
}

p.extra {
  padding: 0px 20px 0px 20px;
  margin-bottom: 10px;
}

p.extra.one {
  padding-top: 10px;
}

/* CREATION WEB */
.creation h3 {
  padding-bottom: 30px;
}

.sub-pack {
  display: flex;
  color: var(--thirdGray);
  padding: 0 0 0px 20px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.7px;
}

.sub-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin: auto;
  padding: 0px;
}

.sub-title-content {
  display: flex;
  padding: 0 0 15px 30px;
  flex-flow: column;
  margin: 10px;
  width: fit-content;
  justify-content: left;
  text-align: left;
}

h3.sub-pack-title {
  display: flex;
  margin: 10px;
  padding: 5px 0 5px 0px;
}

.sub-pack-price {
  display: flex;
  margin: 0;
  padding: 0;
  font-family: "Inter";
  color: var(--mainOrange);
  justify-content: left;
  text-align: left;
}

p.sub-pack-details-title {
  display: flex;
  margin: 0;
  padding: 0 0 5px 20px;
  font-weight: 500;
  justify-content: left;
  text-align: left;
}

p.sub-pack-details {
  display: flex;
  margin: 0;
  padding: 0 0 0 15px;
  justify-content: left;
  text-align: left;
  width: fit-content;
}

p.sub-pack-details:before {
  font-family: "Material Icons";
  content: "\e5cc";
  margin: auto;
  position: relative;
  margin-top: 0;
  color: var(--mainTeal);
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both;
}

@media screen and (max-width: 600px) {
  p.sub-pack {
    padding-top: 20px;
  }
  .sub-container,
  .sub-title-content {
    padding: 0;
    margin: auto;
  }
  .details-container {
    padding: 0;
  }
  .pack-container {
    margin-bottom: 60px;
  }
  .details-container p {
    margin-bottom: 30px;
    margin-top: 5px;
  }
  .pack-middle-elements {
    height: fit-content;
    margin-bottom: 20px;
    margin-top: 30px;
  }
}
