nav {
  height: fit-content;
  text-align: center;
  justify-content: center;
}

nav.navigation {
  position: absolute;
  width: 100%;
  display: flex;
  bottom: 20px;
  margin: auto;
  /*background-color: #aaaaaa;*/
  min-width: 250px;
}

nav ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

nav li {
  display: flex;
  flex-flow: row wrap;
  font-size: 14px;
  padding: 5px;
  align-items: center;
  line-height: 15px;
  font-weight: 500;
  margin: 0 8px 0 0px;
  text-transform: capitalize;
}

nav a {
  font-size: 17px;
}

@media screen and (max-width: 1000px) {
  nav.navigation {
    top: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  nav.navigation {
    position: relative;
    display: flex;
    top: 0;
    height: 50px;
    margin-left: 14px;
  }
  nav.navigation li {
    font-size: 12px;
    padding: 5px;
    align-items: center;
    line-height: 15px;
    font-weight: 600;
    font-weight: 600;
    margin: 10px 1px 10px 0px;
    text-transform: capitalize;
  }
  nav.navigation .material-icons {
    font-size: 18px;
  }

  nav.navigation a {
    font-size: 14px;
  }
}

nav .material-icons {
  font-size: 20px;
}
