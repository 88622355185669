.software nav li.one,
.hardware nav li.one,
.maintenance nav li.one,
.services nav li.one,
.pack nav li.one,
.web nav li.one {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.software nav li.two,
.hardware nav li.two,
.maintenance nav li.two,
.services nav li.two,
.pack nav li.two,
.web nav li.two {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.maintenance nav li.three {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.software nav,
.hardware nav,
.maintenance nav,
.services nav,
.pack nav,
.web nav {
  z-index: 2;
  position: absolute;
  width: 100%;
  display: flex;
  height: fit-content;
  text-align: center;
  overflow: hidden;
  background-color: var(--mainOrange);
  background: rgba(30, 30, 30, 0.8);
  top: 0;
  padding: 5px 0 5px 0;
  -webkit-animation: text-focus-in 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.software nav,
.hardware nav,
.maintenance nav,
.services nav,
.web nav {
  -webkit-animation: fade-out 0.1s ease-out both;
  animation: fade-out 0.1s ease-out both;
}

.software nav.on,
.hardware nav.on,
.maintenance nav.on,
.services nav.on,
.web nav.on {
  left: 0px;
  -webkit-animation: text-focus-in 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

nav ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

nav li {
  display: flex;
  flex-flow: row wrap;
  padding: 5px;
  align-items: center;
  line-height: 15px;
  font-weight: 500;
  text-transform: uppercase;
}

.software nav li a,
.hardware nav li a,
.maintenance nav li a,
.services nav li a,
.pack nav li a,
.web nav li a {
  color: white;
  font-weight: 300;
  padding: 0 10px 0 10px;
  letter-spacing: 1px;
  font-size: 14px;
}

.software nav li a:hover,
.hardware nav li a:hover,
.maintenance nav li a:hover,
.services nav li a:hover,
.pack nav li a:hover,
.web nav li a:hover {
  color: var(--mainOrange);
  font-weight: 300;
  padding: 0 10px 0 10px;
}

.software nav li a.active,
.hardware nav li a.active,
.maintenance nav li a.active,
.services nav li a.active,
.pack nav li a.active,
.web nav li a.active {
  color: var(--secondOrange);
  font-weight: 300;
  padding: 0 10px 0 10px;
}

@media screen and (max-width: 600px) {
  .software nav,
  .hardware nav,
  .maintenance nav,
  .services nav,
  .pack nav,
  .web nav {
    position: absolute;
    background: rgba(30, 30, 30, 0.5);
    top: 0;
  }

  nav {
    position: relative;
    display: flex;
    top: 0;
    height: fit-content;
    padding: 5px;
  }
  nav li {
    font-size: 12px;
    padding: 5px;
    align-items: center;
    line-height: 15px;
    font-weight: 600;
    text-transform: uppercase;
  }
  nav .material-icons {
    font-size: 18px;
  }
}

nav .material-icons {
  font-size: 20px;
}
